* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 18px;
  font-family: 'Lato', Arial, sans-serif;
  line-height: 150%;
  color: #141204;
}

input, button {
  font-family: 'Lato', Arial, sans-serif;
}
